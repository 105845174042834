//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

const { DateTime } = require('luxon')
export default {
  props: {
    blog: Object,
    contexto: String,
    height: Number,
    semFoto: { type: Boolean, default: false },
  },
  data() {
    return {
      DateTime,
    }
  },
}
